const images = import.meta.glob('@/assets/img/*', { eager: true });
const fallbackColor: string = '#F5F5F9';
export const userNoImage = new URL('../../assets/img/no-user-avatar.png', import.meta.url).href;
export const leadDefaultImage = new URL('../../assets/img/lead-icon.png', import.meta.url).href;

/**
 * Gets the URL of an image by its name.
 * Falls back to the default "no-user-avatar" image if the image is not found.
 * @param {string} imageName - The name of the image.
 * @returns {string} - The URL of the image or fallback image.
 */
export function getImageUrl(imageName: string): string {
  if (!imageName) {
    return userNoImage; // Explicitly return the fallback image if imageName is empty
  }
  if (isExternalUrl(imageName)) {
    return imageName; // External URLs are returned as is
  }
  const matchedImage = Object.entries(images).find(([path]) => path.includes(imageName));
  return matchedImage ? (matchedImage[1] as any).default : userNoImage;
}

/**
 * Generates a background image style string or falls back to a color.
 * @param {string} imageName - The name of the image.
 * @returns {Record<string, string>} - Background style object.
 */
export function getBackgroundImageOrColor(imageName: string): Record<string, string> {
  if (!imageName) {
    return { 'background-image': `url(${userNoImage})` };
  }
  if (isExternalUrl(imageName)) {
    return { 'background-image': `url(${imageName})` };
  }
  const imageUrl = getImageUrl(imageName);
  if (imageUrl !== userNoImage) {
    return { 'background-image': `url(${imageUrl})` };
  }
  return { 'background-color': fallbackColor };
}

/**
 * Gets the fallback or specified image URL.
 * @param {string} imageName - The name of the image.
 * @returns {string} - The URL of the image or fallback.
 */
export function getImageOrDefault(imageName: string): string {
  return getImageUrl(imageName);
}

/**
 * Checks if a given URL is external.
 * @param {string} url - The URL to check.
 * @returns {boolean} - Whether the URL is external.
 */
export function isExternalUrl(url: string): boolean {
  return /^https?:\/\//.test(url);
}

/**
 * Generates an SVG href for sprite icons.
 * @param {string | undefined} svgName - The name of the SVG icon.
 * @returns {string} - The href for the SVG icon or an empty string.
 */
export function svgHref(svgName: string | undefined): string {
  if (svgName) {
    return new URL('@/assets/sprites/sprite.svg', import.meta.url).href + `#${svgName}`;
  }
  return '';
}

/**
 * Generates the URL for an icon in the sprite sheet.
 * @param {string} iconName - The name of the icon.
 * @returns {string} - The sprite URL.
 */
export const spriteUrl = (iconName: string) => {
  return new URL(`@/assets/sprites/sprite.svg`, import.meta.url).href + `#ic-${iconName}`;
};
