import { defineStore } from 'pinia';
import axios from '@/common/lib/axios';

import { User_URL } from '@/common/lib/url';

interface Message {
  id: number;
  message: string;
}

interface NotificationState {
  messages: Message[];
  readCount: number;
  unreadCount: number;
}

export const useNotificationStore = defineStore('notification', {
  state: (): NotificationState => ({
    messages: [],
    readCount: 0,
    unreadCount: 0
  }),
  getters: {
    getReadCount: state => state.readCount,
    getUnreadCount: state => state.unreadCount,
    getAllCount: state => state.readCount + state.unreadCount
  },
  actions: {
    async fetchNotifications(readFilter: boolean) {
      try {
        const response = await axios.get(User_URL + 'notifications', {
          params: {
            read: readFilter
          }
        });
        if (response.data.success) {
          return response.data.data;
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    async fetchNotificationsCount() {
      try {
        const response = await axios.get(User_URL + 'notifications/count');
        if (response.data.success) {
          this.setReadCount(response.data.result.read);
          this.setUnreadCount(response.data.result.unread);
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    async markAllAsRead(): Promise<void> {
      try {
        const response = await axios.post(User_URL + 'notifications/markAllAsRead');
        if (response.data.success) {
          this.setReadCount(this.readCount + this.unreadCount);
          this.setUnreadCount(0);
        }
      } catch (error) {
        console.error('Error marking all notifications as read:', error);
      }
    },

    async toggleReadStatus(id: string, status: 'read' | 'unread', readFilter: boolean) {
      try {
        const response = await axios.post(User_URL + 'notifications/read', {
          id,
          status // Send the status (read/unread)
        });

        if (response.data.success) {
          await this.fetchNotificationsCount();
          return await this.fetchNotifications(readFilter); // Refresh the list
        }
      } catch (error) {
        console.error('Error toggling notification read status:', error);
      }
    },

    async deleteNotification(id: string): Promise<boolean> {
      try {
        const response = await axios.post(User_URL + 'notifications/delete', {
          id
        });
        if (response.data.success) {
          await this.fetchNotificationsCount();
          return true;
        } else {
          this.addMessage('Failed to delete notification');
          return false;
        }
      } catch (error) {
        console.error('Error deleting notification:', error);
        return false;
      }
    },
    addMessage(message: string) {
      const id = Date.now();
      this.messages.push({ id, message });
      setTimeout(() => this.removeMessage(id), 6000);
    },
    removeMessage(id: number) {
      this.messages = this.messages.filter(msg => msg.id !== id);
    },
    setReadCount(count: number) {
      this.$patch({ readCount: count });
    },
    setUnreadCount(count: number) {
      this.$patch({ unreadCount: count });
    },
    reset() {
      this.$reset();
    }
  },

  persist: true
});

export default useNotificationStore;
