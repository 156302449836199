<template>
  <section class="main-bar" :class="{ active: status }">
    <router-link to="/main" class="main-bar-logo">
      <div class="main-bar-logo__short">
        <img src="../../assets/img/logo.svg" alt="" />
      </div>
      <div class="main-bar-logo__full">
        <img src="../../assets/img/logo-full.svg" alt="" />
      </div>
    </router-link>

    <div class="main-bar__content">
      <div v-if="hasBusiness" class="main-bar-menu">
        <router-link
          v-for="(item, index) in menu"
          :key="index"
          class="main-bar-menu__item"
          :class="{ active: item.link == linkActiveClass }"
          :to="item.link"
        >
          <div class="main-bar-menu__icon">
            <SvgIcon :icon="item.icon" />
          </div>
          <div class="main-bar-menu__text">{{ item.text }}</div>
        </router-link>
      </div>

      <div v-if="hasBusiness" class="main-bar__arr" @click="uiStore.toggleSideBar()">
        <svg class="icon ic-arrow-right" width="6" height="11">
          <use xlink:href="../../assets/sprites/sprite.svg#ic-arrow-right"></use>
        </svg>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { trans } from '@/common/language/translations';

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { USER_ROLES, UserRoleLevels } from '@/types/interfaces';
import { useAuthStore } from '@/store/auth';
import { useCampaignStore } from '@/store/campaign';
import SvgIcon from '@/components/UI/SvgIcon.vue';
import { useUIStore } from '@/store/ui';

const uiStore = useUIStore();
const route = useRoute();
const authStore = useAuthStore();
const campaignStore = useCampaignStore();

const status = computed(() => uiStore.isMainBarOpen);
const currentBusiness = computed(() => authStore.currentBusiness);
const currentCampaign = computed(() => campaignStore.currentCampaign);
const hasCampaignPermission = computed(() => {
  if (currentCampaign.value) {
    const userRoleLevel = UserRoleLevels[currentCampaign.value.role!];
    const minimumRoleLevel = UserRoleLevels[USER_ROLES.Reader];
    return userRoleLevel <= minimumRoleLevel;
  }
  return true;
});
const hasBusiness = computed(() => authStore.businesses.length > 0);
const leadView = computed(() => {
  if (uiStore.leadView === 'cards') {
    return 'cards';
  } else {
    return 'leads';
  }
});

const leadPath = computed(() => {
  const base = `/b/${currentBusiness.value?._id}`;

  if (currentCampaign.value) {
    const campaignBase = `${base}/c/${currentCampaign.value?._id}/leads`;
    return leadView.value === 'cards' ? `${campaignBase}/cards` : campaignBase;
  } else {
    return leadView.value === 'cards' ? `${base}/leads/cards/all` : `${base}/leads/all`;
  }
});
// todo: implement callPath when products want to show it on sidebar
// const callPath = computed(() => {
//   const base = `/b/${currentBusiness.value?._id}`;
//   if (currentCampaign.value) {
//     return `${base}/c/${currentCampaign.value?._id}/calls`;
//   } else {
//     return `${base}/calls/all`;
//   }
// });

const menu = computed(() => [
  {
    icon: 'ic-home',
    text: trans('Overview'),
    link: '/dashboard',
    permission: true
  },
  {
    icon: 'ic-star',
    text: trans('Campaigns'),
    link: `/b/${currentBusiness.value?._id}/campaigns`,
    permission: hasCampaignPermission
  },

  {
    icon: 'ic-person',
    text: trans('Leads'),
    link: leadPath.value,
    permission: hasCampaignPermission
  },
  // {
  //   icon: 'ic-phone',
  //   text: trans('Calls'),
  //   link: callPath,
  //   permission: true
  // },
  {
    icon: 'ic-people',
    text: trans('Users'),
    link: `/b/${currentBusiness.value?._id}/users`,
    permission: true
  },
  {
    icon: 'ic-settings',
    text: trans('Business'),
    link: `/b/${currentBusiness.value?._id}/settings/profile`,
    permission: true
  }
]);
const filteredMenu = computed(() => {
  return menu.value.filter(item =>
    typeof item.permission === 'boolean' ? item.permission : item.permission.value
  );
});
const linkActiveClass = computed(() => {
  return filteredMenu.value.find(item => item.link === route.path)?.text || '';
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/main-bar.scss';
</style>
