// globalErrorHandler.ts
import { App, ComponentPublicInstance } from 'vue';
import axios from 'axios';

const isProduction = import.meta.env.NODE_ENV === 'production';

export const setupGlobalErrorHandler = (app: App<Element>): void => {
  // Custom Vue warning handler to filter specific warnings
  app.config.warnHandler = (msg, instance, trace) => {
    if (msg.includes('<Suspense> is an experimental feature')) {
      // Suppress the experimental Suspense warning
      return;
    }
    console.warn(`[Vue Warn]: ${msg}${trace}`);
    if (instance) {
      console.warn('Component Name:', instance.$options?.name || 'Anonymous Component');
    }
  };
  app.config.errorHandler = (err, instance, info) => {
    handleGlobalError(err, instance, info, 'Vue Error');
  };

  window.addEventListener('unhandledrejection', event => {
    event.preventDefault();
    handleGlobalError(event.reason, null, '', 'Unhandled Promise Rejection');
  });

  window.addEventListener('error', event => {
    event.preventDefault();
    handleGlobalError(event.error, null, '', 'Global JavaScript Error');
  });

  axios.interceptors.response.use(
    response => response,
    error => {
      handleAxiosError(error);
      return Promise.reject(error);
    }
  );
};

function handleGlobalError(
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string,
  context: string
) {
  const errorMessage = getErrorMessage(err);

  console.groupCollapsed(`[${context}] ${errorMessage}`);
  console.error(`Error Info: ${info}`);
  if (err instanceof Error && err.stack) {
    console.error('Stack Trace:', err.stack);
  }
  if (instance) {
    console.error('Component Context:', instance.$options.name || 'Anonymous Component');
    console.error('Component Props:', instance.$props || 'No props available');
    console.error('Component Data:', instance.$data || 'No data available');
  }
  logErrorDetails(err, instance);
  console.groupEnd();

  if (isProduction) {
    reportErrorToService(err, context, instance);
  }
}

function handleAxiosError(error: any) {
  const status = error?.response?.status || 'Unknown';
  const message = error?.response?.data?.message || error.message || 'Unknown error';
  const url = error?.config?.url || 'Unknown URL';

  console.groupCollapsed(`[Axios Error]`);
  console.error(`Status: ${status}`);
  console.error(`Message: ${message}`);
  console.error(`URL: ${url}`);
  console.error('Request Data:', error?.config?.data || 'No request data');
  console.error('Response Data:', error?.response?.data || 'No response data');
  console.error('Stack Trace:', error.stack || 'No stack trace available');
  console.groupEnd();
}

function getErrorMessage(err: unknown): string {
  if (err instanceof Error) return err.message;
  if (typeof err === 'string') return err;
  return JSON.stringify(err);
}

function logErrorDetails(err: unknown, instance: ComponentPublicInstance | null) {
  if (instance) {
    const componentName = getComponentName(instance);
    console.log('Component Name:', componentName);
    console.log('Props:', instance.$props || 'No props available');
    console.log('Data:', instance.$data || 'No data available');

    logDynamicProperties('Computed Properties', instance, instance.$options.computed);
    logDynamicProperties('Methods', instance, instance.$options.methods);
    logDynamicProperties('Watchers', instance, instance.$options.watch);
    logSlots(instance);
  } else {
    console.warn('No component instance available for this error');
  }
}

function getComponentName(instance: ComponentPublicInstance): string {
  const name = instance.$options.name || instance.$options.__file || 'Anonymous Component';
  return name.includes('Anonymous')
    ? `Anonymous (File: ${instance.$options.__file || 'unknown'})`
    : name;
}

function logDynamicProperties(
  label: string,
  instance: ComponentPublicInstance,
  properties: Record<string, any> | undefined
) {
  if (!properties) return;
  Object.entries(properties).forEach(([key, value]) => {
    const resolvedValue = typeof value === 'function' ? value.bind(instance) : value;
    console.log(`${key}:`, resolvedValue);
  });
}

function logSlots(instance: ComponentPublicInstance) {
  if (instance.$slots) {
    console.log('Slots:', Object.keys(instance.$slots));
  }
}

function reportErrorToService(
  err: unknown,
  context: string,
  instance: ComponentPublicInstance | null
) {
  console.log(`[Error Reporting] Sending error to monitoring service...`);

  const errorDetails = {
    message: getErrorMessage(err),
    stack: err instanceof Error ? err.stack : 'No stack trace available',
    context,
    component: instance ? getComponentName(instance) : 'Unknown Component',
    props: instance?.$props || {},
    data: instance?.$data || {}
  };

  // Example: Send to Sentry or other error tracking tool
  // Sentry.captureException(err, { extra: errorDetails });

  console.debug('Reported Error Details:', errorDetails);
}
