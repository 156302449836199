import { RouteRecordRaw } from 'vue-router';
import { USER_ROLES } from '@/types/interfaces';
import campaignRoutes from '@/router/campaignRoutes';

const CampaignComponent = () => import('@/components/Campaign/CampaignModal/CampaignComponent.vue');
const Campaigns = () => import('@/pages/Campaigns.vue');
const CreateCampaign = () => import('@/components/Campaign/Create Campaign/CreateCampaign.vue');
const BusinessSettings = () => import('@/pages/BusinessSettings/BusinessProfile.vue');
const BusinessSettingBilling = () => import('@/pages/BusinessSettings/BusinessSettingBilling.vue');
const Integrations = () => import('@/pages/BusinessSettings/Integrations.vue');
const LeadStatuses = () => import('@/pages/BusinessSettings/LeadStatuses.vue');
const BusinessNotifications = () => import('@/pages/BusinessSettings/BusinessNotifications.vue');
const SettingApiKeys = () => import('@/pages/BusinessSettings/ApiKeys.vue');
const Users = () => import('@/pages/Users.vue');
const WaitingList = () => import('@/pages/WaitingList.vue');
const Leads = (): Promise<typeof import('@/pages/Leads.vue')> => import('@/pages/Leads.vue');
const LeadCards = (): Promise<typeof import('@/pages/LeadCards.vue')> =>
  import('@/pages/LeadCards.vue');
const Calls = (): Promise<typeof import('@/pages/Calls.vue')> => import('@/pages/Calls.vue');

export const businessRoutes: RouteRecordRaw[] = [
  {
    path: 'c/:campaignId',
    component: CampaignComponent,
    children: campaignRoutes
  },
  {
    path: 'leads/all',
    component: Leads,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.NONE }
  },
  {
    path: 'leads/cards/all',
    component: LeadCards,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.NONE }
  },
  {
    path: 'calls/all',
    component: Calls,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'Campaigns',
    path: 'campaigns',
    component: Campaigns,
    meta: {
      requiresAuth: true,
      businessRequired: USER_ROLES.Reader,
      breadCrumbName: 'Campaigns',
      parent: 'Business'
    }
  },
  {
    name: 'CreateCampaign',
    path: 'create-campaign',
    component: CreateCampaign,
    meta: { requiresAuth: true }
  },
  {
    name: 'BusinessSettings',
    path: 'settings/profile',
    component: BusinessSettings,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'BusinessSettingBilling',
    path: 'settings/billing',
    component: BusinessSettingBilling,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'Integrations',
    path: 'settings/integrations',
    component: Integrations,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'LeadStatuses',
    path: 'settings/leads-statuses',
    component: LeadStatuses,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'BusinessNotifications',
    path: 'settings/notifications',
    component: BusinessNotifications,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    name: 'ApiKeys',
    path: 'settings/api-keys',
    component: SettingApiKeys,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    path: 'users',
    component: Users,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  },
  {
    path: 'waiting-list',
    component: WaitingList,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  }
];

export default businessRoutes;
