import { App, ref } from 'vue';
import { useUIStore } from '@/store/ui';
import { MOBILE_VIEW_THRESHOLD } from '@/common/lib/constant';

interface MobileDetectionPluginOptions {
  thresholdWidth?: number;
}

const MobileDetectionPlugin = {
  install(app: App, options?: MobileDetectionPluginOptions): void {
    const threshold = options?.thresholdWidth || MOBILE_VIEW_THRESHOLD;
    const isMobile = ref(window.innerWidth <= threshold);

    const updateMobileStatus = (): void => {
      isMobile.value = window.innerWidth <= threshold;
      const uiStore = useUIStore();
      uiStore.updateViewport(isMobile.value);
    };

    window.addEventListener('resize', updateMobileStatus);
    updateMobileStatus(); // Initial update
  }
};

export default MobileDetectionPlugin;
