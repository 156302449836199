import { defineStore } from 'pinia';

interface LoaderState {
  isRequestLoading: boolean;
  isRouteLoading: boolean;
  requestMap: Record<string, Date>; // Use Record instead of Map
  minimumLoadingTime: number;
}

export const useLoadingStore = defineStore('loading', {
  state: (): LoaderState => ({
    isRequestLoading: false,
    isRouteLoading: false,
    requestMap: {}, // Initialize as an empty object
    minimumLoadingTime: 500
  }),
  getters: {
    isLoading(state: LoaderState): boolean {
      return state.isRequestLoading || state.isRouteLoading;
    }
  },
  actions: {
    generateReqId(): string {
      return Math.random().toString(36).substring(2) + Date.now().toString(36);
    },
    startRequest(): string {
      const reqId = this.generateReqId();
      this.requestMap[reqId] = new Date(); // Assign date using object syntax
      this.isRequestLoading = true;
      return reqId;
    },
    endRequest(reqId: string): void {
      delete this.requestMap[reqId]; // Use delete operator for Record
      this.isRequestLoading = Object.keys(this.requestMap).length > 0; // Check if there are still pending requests
    },
    startRouteLoading(): void {
      this.isRouteLoading = true;
    },
    endRouteLoading(): void {
      this.isRouteLoading = false;
    },
    handleLoadingCompletion(reqId: string): void {
      const requestStartTime = this.requestMap[reqId]?.getTime();
      if (requestStartTime) {
        const elapsedTime = Date.now() - requestStartTime;
        const remainingTime = this.minimumLoadingTime - elapsedTime;
        if (remainingTime > 0) {
          setTimeout(() => {
            this.endRequest(reqId);
          }, remainingTime);
        } else {
          this.endRequest(reqId);
        }
      } else {
        this.endRequest(reqId);
      }
    },
    resetLoadingState(): void {
      this.isRequestLoading = false;
      this.isRouteLoading = false;
      this.requestMap = {};
    }
  },
  persist: false
});

export default useLoadingStore;
