<template>
  <div class="choose-acc" :class="{ open: openPopup }" v-click-outside="handleClose">
    <div class="choose-acc__top" @click="toggleSelectOpen">
      <div
        v-if="currentCampaign && (!allLeads || !allCalls)"
        class="choose-acc__img"
        :style="{ 'background-image': `url(${avatarUrl})` }"
      ></div>

      <div v-if="isSelectAll" class="choose-acc__text">
        {{ trans('All campaigns') }}
      </div>
      <div v-else class="choose-acc__text">
        {{ currentCampaign.name }}
      </div>
      <div class="choose-acc__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="../../../assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>

    <div class="choose-acc-popup">
      <div class="choose-acc-popup__search">
        <input v-model="searchQuery" type="text" placeholder="Search" />
        <svg class="icon ic-search" width="15" height="15">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
        </svg>
      </div>

      <div
        v-for="item in filteredCampaigns"
        :key="item._id"
        :class="['choose-acc-popup__item', { active: isActive(item) }]"
        @click="changeOption(item._id)"
      >
        <div class="choose-acc-popup__content">
          <div
            v-if="item.image"
            class="choose-acc-popup__img"
            :style="{ 'background-image': `url(${item.image!})` }"
          ></div>

          <div class="choose-acc-popup__info">
            <div :class="['choose-acc-popup__title', { expanded: item._id === 'all' }]">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div v-if="isActive(item)" class="choose-acc-popup__check">
          <svg class="icon ic-tick" width="8" height="6">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useCampaignStore } from '@/store/campaign';
import { ICampaign } from '@/types/interfaces';
import { useUIStore } from '@/store/ui';
import useAuthStore from '@/store/auth';
import { useRouter } from 'vue-router';
import { trans } from '@/common/language/translations';
import { debounce } from 'lodash';
import axios from '@/common/lib/axios';
import { Campaign_URL } from '@/common/lib/url';
import { showToast } from '@/common/lib/toast';

const props = defineProps({
  isSelectAll: {
    type: Boolean,
    required: false
  },
  allType: {
    type: String as () => 'leads' | 'calls' | 'dashboard' | '',
    required: false
  }
});
const emit = defineEmits(['close', 'toggle', 'select']);

const router = useRouter();
const campaignStore = useCampaignStore();
const uiStore = useUIStore();
const authStore = useAuthStore();

const searchQuery = ref('');
const openPopup = ref(false);
const originalCampaigns = ref<ICampaign[]>([]);
const searchResults = ref<ICampaign[]>([]);

const campaigns = computed(() =>
  searchQuery.value.trim() ? searchResults.value : originalCampaigns.value
);
const allLeads = computed(() => uiStore.allLeads);
const allCalls = computed(() => uiStore.allCalls);
const currentCampaign = computed(() => campaignStore.currentCampaign!);
const avatarUrl = computed(() =>
  currentCampaign.value?.image ? `${currentCampaign.value?.image}` : ''
);
const currentBusiness = computed(() => authStore.currentBusiness);
const leadView = computed(() => {
  if (uiStore.leadView === 'cards') {
    return 'cards';
  } else {
    return 'leads';
  }
});
const filteredCampaigns = computed(() => campaigns.value);
const leadPath = computed(() => {
  const base = `/b/${currentBusiness.value?._id}`;

  if (currentCampaign.value) {
    const campaignBase = `${base}/c/${currentCampaign.value?._id}/leads`;
    return leadView.value === 'cards' ? `${campaignBase}/cards` : campaignBase;
  } else {
    return leadView.value === 'cards' ? `${base}/leads/cards/all` : `${base}/leads/all`;
  }
});
const callPath = computed(() => {
  const base = `/b/${currentBusiness.value?._id}`;
  if (currentCampaign.value) {
    return `${base}/c/${currentCampaign.value?._id}/calls`;
  } else {
    return `${base}/calls/all`;
  }
});

const isActive = (item: ICampaign) => {
  if (item._id === 'all') {
    return (
      (props.allType === 'leads' && allLeads.value) ||
      (props.allType === 'calls' && allCalls.value) ||
      props.isSelectAll
    );
  }
  return item._id === currentCampaign.value?._id;
};

async function changeOption(id: string): Promise<void> {
  if (id === 'all') {
    if (props.allType === 'leads') {
      uiStore.setAllLeads(true);
      uiStore.setAllCalls(false);
    } else if (props.allType === 'calls') {
      uiStore.setAllCalls(true);
      uiStore.setAllLeads(false);
    }
    campaignStore.currentCampaign = null;
    if (props.allType === 'dashboard') {
      toggleSelectOpen();
      return;
    }
    await router.push(props.allType === 'leads' ? leadPath.value : callPath.value);
    toggleSelectOpen();
    return;
  }

  const selectedCampaign = campaigns.value.find(campaign => campaign._id === id);
  if (!selectedCampaign) {
    return;
  }
  if (props.allType === 'leads') {
    uiStore.setAllLeads(false);
  } else if (props.allType === 'calls') {
    uiStore.setAllCalls(false);
  }

  await campaignStore.setCurrentCampaign(selectedCampaign);
  toggleSelectOpen();
}

function toggleSelectOpen(): void {
  openPopup.value = !openPopup.value;
}

function handleClose(): void {
  openPopup.value = false;
}

const debouncedSearch = debounce(async (query: string) => {
  if (query.length > 0) {
    try {
      const response = await axios.get(Campaign_URL + 'search', { params: { search: query } });
      if (!response.data.success || response.data.campaigns.length === 0) {
        showToast('No data found', 'info');
        return;
      }
      searchResults.value = response.data.campaigns;
    } catch (error) {
      console.error('Error searching campaigns:', error);
    }
  }
}, 300);

onMounted(() => {
  const allOption: ICampaign = {
    _id: 'all',
    name: trans('All campaigns'),
    image: '',
    links: [],
    tags: [],
    startDate: '',
    endDate: '',
    leads: [],
    totalSales: 0,
    leadsCount: 0,
    business_id: '',
    email: '',
    businessId: [],
    isEnabled: false,
    smsNotifications: [],
    emailNotifications: [],
    virtualNumber: null,
    notes: '',
    facebookCampaignId: '',
    role: undefined,
    userCount: 0
  };
  originalCampaigns.value = [allOption, ...campaignStore.campaigns];
});

watch(searchQuery, newValue => {
  debouncedSearch(newValue);
});
</script>

<style lang="scss"></style>
