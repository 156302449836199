<template>
  <router-link
    v-if="link"
    :to="link"
    class="btn-circle"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <slot></slot>
    <svg v-if="icon" :class="`icon ic-${icon}`" :width="iconWidth" :height="iconHeight">
      <use :xlink:href="spriteUrl(icon)"></use>
    </svg>
    <span v-if="tooltip" v-show="showTooltip" class="tooltip">{{ tooltip }}</span>
  </router-link>

  <div v-else class="btn-circle" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
    <slot></slot>
    <svg v-if="icon" :class="`icon ic-${icon}`" :width="iconWidth" :height="iconHeight">
      <use :xlink:href="spriteUrl(icon)"></use>
    </svg>
    <span v-if="tooltip" v-show="showTooltip" class="tooltip">{{ tooltip }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { spriteUrl } from '@/common/lib/utils';

defineProps({
  link: {
    type: String,
    default: ''
  },
  tooltip: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: '',
    required: false
  },
  iconFill: {
    type: String,
    required: false
  },
  iconWidth: {
    type: String,
    default: '20',
    required: false
  },
  iconHeight: {
    type: String,
    default: '20',
    required: false
  }
});

const showTooltip = ref(false);
</script>

<style lang="scss">
@import '@/assets/styles/btn-circle.scss';
</style>
