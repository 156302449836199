import enTranslations from './locales/en.json';
import heTranslations from './locales/he.json';

export const enum LanguageNames {
  en = 'English',
  he = 'עברית'
}

export const translations = {
  en: { name: 'English', dir: 'ltr', data: enTranslations },
  he: { name: 'Hebrew', dir: 'rtl', data: heTranslations }
} as const;

export type Languages = keyof typeof translations;

export const LanguageKeys = Object.keys(translations) as Languages[];

export function isLanguageKey(key: string): key is Languages {
  return LanguageKeys.includes(key as Languages);
}

export const defaultLanguage: Languages = 'he';

export type TranslationKeys = keyof (typeof translations)[Languages]['data'];

export function translate(
  key: TranslationKeys,
  variables?: Record<string, string | number>,
  language: Languages = defaultLanguage
): string {
  return (
    (variables
      ? (translations[language].data as Record<TranslationKeys, string>)[key]?.replace(
          /%(\w+)%/g,
          (_: string, varName: string) => variables?.[varName] + '' || _
        )
      : (translations[language].data as Record<TranslationKeys, string>)[key]) ?? key
  );
}

export function trans(key: TranslationKeys, language: Languages = defaultLanguage): string {
  return translations[language].data[key] ?? key;
}
