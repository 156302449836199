import useLoadingStore from '@/store/loading';
import axios from '@/common/lib/axios';

type ErrorStatusCodes = 204 | 400 | 401 | 403 | 404 | 500 | 502 | 503 | 504;

export async function get<T = object, E = object>(
  path: string,
  options?: { params?: Record<string, any> }
): Promise<[200, T] | [ErrorStatusCodes, E]> {
  const loadingStore = useLoadingStore();
  const reqId = loadingStore.startRequest();

  try {
    const response = await axios.get<T>(path, options);
    return [response.status as 200, response.data];
  } catch (error: any) {
    if (error.response) {
      return [error.response.status as ErrorStatusCodes, error.response.data as E];
    } else {
      console.error('Network or unexpected error:', error);
      return [500, error as E];
    }
  } finally {
    loadingStore.handleLoadingCompletion(reqId);
  }
}

export type BackendResponse<T extends object = { success: boolean }, E extends object = object> =
  | [200, T]
  | [ErrorStatusCodes, E];

export async function post<T extends object = { success: boolean }, E extends object = object>(
  path: string,
  body: object = {}
): Promise<BackendResponse<T, E>> {
  const loadingStore = useLoadingStore();
  const reqId = loadingStore.startRequest();

  try {
    const response = await axios.post<T>(path, body);
    return [response.status as 200, response.data];
  } catch (error: any) {
    if (error.response) {
      return [error.response.status as ErrorStatusCodes, error.response.data as E];
    } else {
      console.error('Network or unexpected error:', error);
      return [500, error as E];
    }
  } finally {
    loadingStore.handleLoadingCompletion(reqId);
  }
}
