<template>
  <div class="navigator">
    <div class="btn-back" :class="{ hidden: !hasBack }" @click="breadcrumbStore.back()">
      <span class="btn-back__icon">
        <SvgIcon icon="ic-arr-left" width="16" height="16" color="white" />
      </span>
      {{ trans('Back') }}
    </div>
    <SelectCampaign v-if="isSelectAll" :is-select-all="isSelectAll" :all-type="allType" />
  </div>
</template>
<script setup lang="ts">
import { trans } from '@/common/language/translations';
import SvgIcon from '@/components/UI/SvgIcon.vue';
import { computed, ref, watch } from 'vue';
import useBreadcrumbStore from '@/store/bread-crumbs';
import { useRoute } from 'vue-router';
import SelectCampaign from '@/components/Campaign/CampaignModal/SelectCampaign.vue';

const breadcrumbStore = useBreadcrumbStore();
const route = useRoute();

const hasBack = ref<boolean>(false);

const AllLeads = computed(() => route.fullPath.includes('/leads'));
const AllCalls = computed(() => route.fullPath.includes('/calls'));
const AllData = computed(() => route.fullPath === '/main');

const allType = computed<'leads' | 'calls' | 'dashboard' | ''>(() => {
  if (AllLeads.value) return 'leads';
  if (AllCalls.value) return 'calls';
  if (AllData.value) return 'dashboard';
  return '';
});

const isSelectAll = computed(() => AllLeads.value || AllCalls.value || AllData.value);

watch(
  () => [route.fullPath, breadcrumbStore.breadcrumbs.length],
  () => {
    hasBack.value = breadcrumbStore.breadcrumbs.length > 0 || history.state.back !== null;
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss"></style>
