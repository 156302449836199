<template>
  <Mob_Modal :openModal="openNotice" @close="onClose">
    <div v-if="!isMobileView" class="modal__top">
      <my-button-circle class="btn-circle_grey" :icon="'close'" @click="onClose" />
    </div>

    <div class="modal__content">
      <div class="notice-info">
        <div class="notice-info__top">
          <div class="notice-info__title">{{ trans('Notifications') }}</div>

          <div class="notice-info__status notice-info__status_green" @click="handleMarkAllAsRead">
            <svg class="icon ic-check" width="17" height="9">
              <use xlink:href="@/assets/sprites/sprite.svg#ic-check"></use>
            </svg>
            {{ trans('Mark all as read') }}
          </div>
        </div>

        <div class="tabs-page tabs-page_line">
          <TabHeader v-model="activeTab" :tabs="tabs" />

          <TabContent v-if="activeTab === 'all'" :is-active="true" />
          <TabContent
            v-else-if="activeTab === 'unread'"
            :read-filter="false"
            :is-active="true"
            @refresh-count="fetchNotificationsCount"
          />
          <TabContent
            v-else-if="activeTab === 'read'"
            :read-filter="true"
            :is-active="true"
            @refresh-count="fetchNotificationsCount"
          />
        </div>
      </div>
    </div>
  </Mob_Modal>
</template>
<script setup lang="ts">
import { trans } from '@/common/language/translations';

import { computed, onMounted, ref } from 'vue';
import MyButtonCircle from '../UI/MyButtonCircle.vue';
import TabHeader from '@/components/Notifications/TabHeader.vue';
import TabContent from '@/components/Notifications/TabContent.vue';
import axios from '@/common/lib/axios';
import { useNotificationStore } from '@/store/notification';
import Mob_Modal from '@/mobile_components/Mob_Modal.vue';
import { useUIStore } from '@/store/ui';
import { User_URL } from '@/common/lib/url';

export interface NotificationTabHeader {
  id: string;
  label: string;
  count: number;
  countClass?: string;
}

interface NotificationsCountResponse {
  success: boolean;
  result: {
    read: number;
    unread: number;
  };
}

defineProps({
  openNotice: {
    type: Boolean,
    default: false,
    required: true
  }
});
const emit = defineEmits(['close', 'allNotificationsRead']);

const notificationStore = useNotificationStore();
const uiStore = useUIStore();
const activeTab = ref('all');

const isMobileView = computed(() => uiStore.isMobile);
const readCount = computed(() => notificationStore.getReadCount);
const unreadCount = computed(() => notificationStore.getUnreadCount);
const allCount = computed(() => notificationStore.getAllCount);

const tabs = computed<NotificationTabHeader[]>(() => [
  {
    id: 'all',
    label: trans('All'),
    count: allCount.value,
    countClass: 'tabs-page__count_red'
  },
  {
    id: 'unread',
    label: trans('Unread'),
    count: unreadCount.value
  },
  {
    id: 'read',
    label: trans('Read'),
    count: readCount.value
  }
]);

const fetchNotificationsCount = async () => {
  try {
    const response = await axios.get<NotificationsCountResponse>(User_URL + 'notifications/count');
    if (response.data.success) {
      const notificationStore = useNotificationStore();
      notificationStore.setReadCount(response.data.result.read);
      notificationStore.setUnreadCount(response.data.result.unread);
    }
  } catch (error) {
    console.error('An error occurred while fetching notifications count:', error);
  }
};

const setupNotificationFetching = () => {
  fetchNotificationsCount();
  setInterval(fetchNotificationsCount, 600000); // Fetch every 10 minutes (600000ms)
};

onMounted(() => {
  setupNotificationFetching();
});

const handleMarkAllAsRead = async () => {
  await notificationStore.markAllAsRead();
};

const onClose = () => {
  activeTab.value = 'all';
  emit('close');
};
</script>

<style lang="scss">
@import '@/assets/styles/tabs-page.scss';
</style>
