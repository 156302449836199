import axios from 'axios';

export const apiUrl = import.meta.env.VITE_BACKEND_URL;

const instance = axios.create({
  baseURL: apiUrl
});

instance.interceptors.request.use(
  config => {
    const tokenInStore = localStorage.getItem('token');
    if (tokenInStore) {
      config.headers.Authorization = `Bearer ${tokenInStore}`;
    }
    const businessId = localStorage.getItem('businessId');
    if (businessId) {
      config.headers['x-business-id'] = businessId;
    }
    config.headers['Accept-Language'] = localStorage.getItem('lang') || 'he';
    config.withCredentials = true;
    return config;
  },
  error => {
    // Handle request error here
    return Promise.reject(error);
  }
);

export default instance;
