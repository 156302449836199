<template>
  <transition name="fade">
    <div class="thank-you-overlay" v-if="isActive">
      <div class="thank-you-message">
        <h3>Thank you for your selection!</h3>
        <p>Your response has been recorded.</p>
        <button @click="closeOverlay">Close</button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThankYouPopUp',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    function closeOverlay() {
      emit('close');
    }

    return {
      closeOverlay
    };
  }
});
</script>

<style scoped lang="scss"></style>
