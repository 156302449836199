import { DirectiveBinding, onUnmounted, Ref } from 'vue';

interface ClickOutsideElement extends HTMLElement {
  __handleClickOutside__: (event: Event) => void;
}

export const clickOutsideDirective = {
  mounted(el: ClickOutsideElement, binding: DirectiveBinding<(event: Event) => void>): void {
    const handleClickOutside = (event: Event): void => {
      // Skip if click is inside the element or inside any child popup
      if (el.contains(event.target as Node) || event.composedPath().includes(el)) {
        return;
      }
      // If it's an outside click, execute the handler
      binding.value(event);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    el.__handleClickOutside__ = handleClickOutside;
  },

  unmounted(el: ClickOutsideElement): void {
    document.removeEventListener('mousedown', el.__handleClickOutside__);
    document.removeEventListener('touchstart', el.__handleClickOutside__);
  }
};

//todo: delete this function
export function useClickOutside(elementRef: Ref<HTMLElement | null>, callback: () => void): void {
  const handleClick = (e: MouseEvent) => {
    if (elementRef.value && !elementRef.value.contains(e.target as Node)) {
      callback();
    }
  };

  const registerEvent = () => {
    document.addEventListener('click', handleClick);
  };

  const unregisterEvent = () => {
    document.removeEventListener('click', handleClick);
  };

  registerEvent();

  // Cleanup when the component is unmounted
  onUnmounted(unregisterEvent);
}

export default clickOutsideDirective;
