<template>
  <div :class="['loader-overlay', { open: isLoading }, theme]">
    <div ref="lottieContainer" class="lottie-animation"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import lightLoader from '@/assets/animations/AtmosLoader_Light.json';

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  }
});

const isDay = ref(localStorage.getItem('darkMode') !== 'true');
const theme = computed(() => (isDay.value ? 'light' : 'dark'));
const lottieContainer = ref<HTMLElement | null>(null);
let lottieInstance: any = null;

async function initializeAnimation(animationData: any): Promise<void> {
  if (lottieInstance) {
    lottieInstance.destroy();
  }

  const { default: lottie } = await import('lottie-web');

  lottieInstance = lottie.loadAnimation({
    container: lottieContainer.value!,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  });

  if (props.isLoading) {
    lottieInstance.play();
  }
}

onMounted(() => {
  initializeAnimation(lightLoader);
});

watch(theme, async newTheme => {
  await initializeAnimation(lightLoader);
});

watch(
  () => props.isLoading,
  newVal => {
    if (lottieInstance) {
      if (newVal) {
        lottieInstance.play();
      } else {
        lottieInstance.stop();
      }
    }
  }
);
</script>

<style scoped lang="scss">
.loader-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transition:
    visibility 0s,
    opacity 0.3s ease;
  backdrop-filter: blur(10px);

  .lottie-animation {
    width: 10rem;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &.dark {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
