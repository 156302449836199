<template>
  <svg :class="'icon ' + icon" :viewBox="viewBox" :width="icon_width" :height="icon_height">
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  icon_width: {
    type: [String, Number],
    default: '24'
  },
  icon_height: {
    type: [String, Number],
    default: '24'
  },
  color: {
    type: String,
    default: 'currentColor'
  },
  viewBox: {
    type: String,
    default: '0 0 24 24'
  }
});

const iconPath = computed(() => {
  return new URL(`@/assets/sprites/sprite.svg`, import.meta.url).href + '#' + props.icon;
});
</script>

<style lang="scss"></style>
