import { defineStore } from 'pinia';

export interface Breadcrumb {
  path?: string;
  name?: string;
  type: 'route' | 'modal';
  closeCb?: () => void;
}

interface BreadcrumbState {
  breadcrumbs: Breadcrumb[];
}

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: (): BreadcrumbState => ({
    breadcrumbs: []
  }),
  actions: {
    addBreadcrumb(route: Breadcrumb) {
      this.breadcrumbs.push(route);
    },
    removeLastBreadcrumb() {
      this.breadcrumbs.pop();
    },
    clearBreadcrumbs() {
      this.breadcrumbs = [];
    },
    back(steps = 1) {
      if (this.breadcrumbs.length === 0) {
        history.go(-1 * steps);
        return;
      }
      for (let i = Math.min(this.breadcrumbs.length, steps); i > 0; i--) {
        const lastBreadcrumb = this.breadcrumbs.pop();
        if (lastBreadcrumb?.type === 'modal' && lastBreadcrumb.closeCb) {
          lastBreadcrumb.closeCb();
        }
      }
    }
  },
  persist: false
});

export default useBreadcrumbStore;
