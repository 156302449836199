import {
  trans as _trans,
  translate as _translate,
  TranslationKeys
} from '@/common/language/translator';
import useLocaleStore from '@/store/locale';

let langCache: ReturnType<typeof useLocaleStore> | undefined = undefined;

export function translate(
  key: TranslationKeys,
  variables?: Record<string, string | number>
): string {
  return _translate(key, variables, (langCache ??= useLocaleStore()).currentLang);
}

export function trans(key: TranslationKeys): string {
  return _trans(key, (langCache ??= useLocaleStore()).currentLang);
}
