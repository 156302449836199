import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import userRoutes from '@/router/userRoutes';
import businessRoutes from '@/router/businessRoutes';
import authRoutes from '@/router/authRoutes';
import mainRoutes from '@/router/mainRoutes';

const BusinessComponent = () => import('@/components/Business/BusinessComponent.vue');
const Dashboard = () => import('@/pages/Dashboard.vue');

const routes: RouteRecordRaw[] = [
  ...mainRoutes,
  { path: '/user', children: userRoutes },
  {
    path: '/b/:businessId',
    component: BusinessComponent,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true, breadCrumbName: 'dashboard' }
      },
      ...businessRoutes
    ],
    meta: { breadCrumbName: 'Business', parent: 'Dashboard' }
  },
  ...authRoutes
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
  linkActiveClass: 'active'
});

export default router;
