import { defineStore } from 'pinia';
import {
  defaultLanguage,
  isLanguageKey,
  Languages,
  translations
} from '@/common/language/translator';
import dayjs from '@/common/lib/datetime';

interface LocaleState {
  currentLang: Languages;
}

// Always prioritize `lang` from localStorage, default to 'he' if absent
const langFromStorage = (localStorage.getItem('lang') as Languages) || defaultLanguage;

const currentLang: Languages = isLanguageKey(langFromStorage) ? langFromStorage : defaultLanguage;

dayjs.locale(currentLang);

export function updateHtml(lang: Languages): void {
  try {
    const htmlEl = document.documentElement;
    htmlEl.setAttribute('lang', lang);
    htmlEl.setAttribute('dir', translations[lang].dir);
  } catch (e) {
    console.error('Failed to update html lang attribute', e);
  }
}

updateHtml(currentLang);

export const useLocaleStore = defineStore('locale', {
  state: (): LocaleState => ({
    currentLang
  }),
  getters: {},
  actions: {
    setLanguage(lang: Languages) {
      if (!isLanguageKey(lang)) {
        return;
      }
      this.currentLang = lang;
      localStorage.setItem('lang', lang);
      dayjs.locale(lang);
      updateHtml(lang);
    }
  }
});

export default useLocaleStore;
