import { Id, toast, ToastContainerOptions, ToastType } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const toastOptions: ToastContainerOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  pauseOnHover: true,
  closeButton: false,
  multiple: true,
  limit: 3,
  icon: true,
  containerClassName: 'toast-container',
  toastClassName: 'toast',
  style: {
    opacity: '1',
    userSelect: 'initial',
    fontFamily: 'var(--font-site)'
  }
};

export function showToast(message: string, type: ToastType = 'default'): Id {
  const specificOptions: ToastContainerOptions = {
    ...toastOptions,
    toastClassName: `notice notice--${type}`
  };

  return toast(message, { ...specificOptions, type });
}
