<template>
  <div class="modal" :class="{ open: openModal }" v-click-outside="onClose">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import useBreadcrumbStore from '@/store/bread-crumbs';

const props = defineProps({
  openModal: {
    type: Boolean,
    required: true
  }
});
const emit = defineEmits(['close']);

const breadcrumbsStore = useBreadcrumbStore();

function onClose() {
  emit('close');
}

watch(
  () => props.openModal,
  isOpen => {
    if (isOpen) {
      breadcrumbsStore.addBreadcrumb({
        type: 'modal',
        closeCb() {
          emit('close');
        }
      });
    } else {
      breadcrumbsStore.removeLastBreadcrumb();
    }
  }
);
</script>

<style lang="scss"></style>
