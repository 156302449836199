import _dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import { trans } from '@/common/language/translations';
import useLocaleStore from '@/store/locale';

const dayjs = _dayjs;
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Jerusalem');

export function formatWithTime(date: Date | string): string {
  const { currentLang } = useLocaleStore();
  const isHebrew = currentLang === 'he';

  const timeFormat = isHebrew ? 'HH:mm' : 'h:mm A';
  const formattedTime = dayjs(date).tz('Asia/Jerusalem').format(timeFormat);
  const formattedDate = dayjs(date).tz('Asia/Jerusalem').format('D MMMM');

  return `${formattedDate} ${trans('at')} ${formattedTime}`;
}

export function formatDateOnly(date: string): string {
  return dayjs(date).tz('Asia/Jerusalem').format('DD.MM.YYYY');
}

export function formatFullDate(dateString: string): string {
  return dayjs(dateString).tz('Asia/Jerusalem').format('D MMMM YYYY');
}

export default dayjs;
