<template>
  <div class="tabs-page" :class="{ active: isActive }">
    <NotificationItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      @update:is-read="toggleReadStatus"
      @delete="handleDelete"
    />
  </div>
</template>

<script lang="ts">
import NotificationItem from '@/components/Notifications/NotificationItem.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useNotificationStore } from '@/store/notification';
import { INotification } from '@/types/interfaces';

export default defineComponent({
  name: 'TabContent',
  components: {
    NotificationItem
  },
  props: {
    readFilter: {
      type: Boolean,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['refresh-count', 'delete'],
  setup(props, { emit }) {
    const items = ref<INotification[]>([]);
    const notificationStore = useNotificationStore();

    async function fetchNotice() {
      items.value = await notificationStore.fetchNotifications(props.readFilter);
    }

    onMounted(async () => {
      await fetchNotice();
    });

    const toggleReadStatus = async (id: string, isRead: boolean) => {
      const newStatus = isRead ? 'unread' : 'read';
      items.value = await notificationStore.toggleReadStatus(id, newStatus, props.readFilter);
      emit('refresh-count');
    };

    const handleDelete = async (id: string) => {
      const success = await notificationStore.deleteNotification(id);
      if (success) {
        await fetchNotice();
        emit('refresh-count');
      }
    };
    return { items, toggleReadStatus, handleDelete };
  }
});
</script>
<style lang="scss">
//@import '@/assets/styles/tabs-page.scss';
</style>
